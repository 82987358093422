@import '../../../style/varibale';


.why_us_app .why_us_container {
    width: 70%;
    margin: auto;
    margin-top: 75px;
    margin-bottom: 175px;
    @media screen and (width < 767px) {
        width: 95%; 

    }
    header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 100px;
        @media screen and (width < 767px) {
            margin-bottom: 30px;
    
        }
        span {
            color: $mainColor;
            font-family: Cairo;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            position: relative;
            text-align: center;

            &::after {
                content: '';
                position: absolute;
                background: $mainColor;
                width: 100%;
                height: 1px;
                left: 0;
                bottom: -5px;
            }
        }
    }

    .img_overlay {
        position: relative;
        @media screen and (width < 767px) {
            margin-top: 30px;
        }

        img {
            border-radius: 10px !important;
        }

        .overlay {
            position: absolute;
            inset: 0;
            background: rgba(0, 0, 0, 0.70);
            border-radius: 10px !important;
     
            span {
                color: #FFF;
                text-align: right;
                font-family: Cairo;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: capitalize;
            }

            p {
                color: #FFF;
                text-align: center;
                font-family: Cairo;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
                display: none;
                margin-top: 25px;
            }

            &:hover {
                p {
                    display: flex;
                }

                transition: all 2s !important;
            }
        }
    }
}