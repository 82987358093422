@import '../../style/varibale';


.app_blogs {

    overflow: hidden;

    .header_banner {
        .overlay .header {
            span {
                display: block;
    width: 100%;
                @media screen and (width < 1200px) {
                    font-size: 30px;

                }

                @media screen and (width < 900px) {
                    font-size: 25px;

                }

                @media screen and (width < 700px) {
                    font-size: 20px;
                    text-align: center; 
                }
            }
        }
    }

    .app_blog_container {
        width: 80%;
        margin: auto;
        margin-top: 125px;

        @media screen and (width < 900px) {
            width: 90%;
        }

        @media screen and (width < 767px) {
            width: 95%;
        }

        .blog_padding {
            padding: 30px;

            @media screen and (width < 900px) {
                padding: 15px;

            }
        }

        .blof_contact {
            padding: 0 !important;

            .blog_img {
                position: relative;
                z-index: 0;

                img {
                    border-radius: 36px;
                    width: 100%;

                }

                .blog_date {
                    position: absolute;

                    top: 50px;
                    right: 50px;

                    @media screen and (width < 900px) {
                        top: 10px;
                        right: 30px;

                    }

                    span {
                        color: #FFF;
                        font-family: Cairo;
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;

                        @media screen and (width < 900px) {
                            font-size: 20px;

                        }
                    }
                }
            }

            .blog_info {
                background-color: #FFF;
                margin: auto;
                margin-top: -50px;
                position: relative;
                z-index: 99 !important;
                width: 90%;
                padding: 20px 40px;
                border-radius: 10px;

                @media screen and (width < 900px) {
                    padding: 20px 20px;

                }

                .title {
                    color: #282828;

                    font-family: Cairo;
                    font-size: 23px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: capitalize;

                    @media screen and (width < 900px) {
                        font-size: 18px;

                    }
                }

                p {
                    color: #282828;

                    font-family: Cairo;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-top: 30px;
                    text-transform: capitalize;

                    @media screen and (width < 767px) {
                        font-size: 15px;
                        margin-top: 20px;


                    }
                }

                .btn_more {
                    span {
                        color: #C4AB73;
                        font-family: Cairo;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: capitalize;

                        @media screen and (width < 900px) {
                            font-size: 16px;
                        }
                    }

                    &::after {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}

[dir="ltr"] .blog_info .btn_more svg {
    transform: rotate(-180deg);
    margin-left: 8px;
}


[dir="ltr"] .blof_contact .blog_img .blog_date {
    position: absolute;
    top: 50px;
    left: 50px;

    @media screen and (width < 900px) {
        top: 10px;
        left: 30px;

    }
}

.app_blogs_details {
    .p-button {
        border-radius: 10px;
        padding: 5px 20px !important;
        background-color: transparent;
        border-color: $mainColor;
        display: flex;
        gap: 5px;
    }

    h3 {
        color: #383B52;
        font-family: Cairo;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;

        @media screen and (width < 1200px) {
            font-size: 30px;

        }

        @media screen and (width < 900px) {
            font-size: 25px;

        }

        @media screen and (width < 700px) {
            font-size: 20px;
            text-align: center;
        }
    }

    .header {
        @media screen and (width < 700px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }
    }

    .p-button-icon,
    .p-button-label {
        color: #C4AB73;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media screen and (width < 700px) {
            font-size: 16px;
        }
    }
}


.blog_details_data {

    h1,
    h3 {
        color: #383B52;
        font-family: Cairo;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;

        @media screen and (width < 1200px) {
            font-size: 30px;

        }

        @media screen and (width < 900px) {
            font-size: 30px;

        }

        @media screen and (width < 700px) {
            font-size: 20px;

        }
    }

    span {
        color: #C4AB73;
        font-family: Cairo;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }

    p {
        color: #282828;
        text-align: right;
        font-family: Cairo;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        /* 320.833% */
        text-transform: capitalize;
    }


    ul {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        li {
            span {
                background-color: transparent !important;

                @media screen and (width < 1200px) {
                    font-size: 30px;

                }

                @media screen and (width < 900px) {
                    font-size: 30px;

                }

                @media screen and (width < 700px) {
                    font-size: 16px;

                }
            }
        }
    }
}